@media screen and (min-width: 800px) {
    .mobile-banner-ad {
        display: none !important;
    }
}

#page-outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
}

.outer {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.outer > div {
    width: 100%;
}

.outer-ad {
    max-height: 100vh;
}

@media screen and (max-width: 800px) {
    .outer-ad-left {
        display: none !important;
    }

    .outer-ad-right {
        display: none !important;
    }

    .outer {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .outer-ad-right {
        width: 200px;
    }
}

@media screen and (max-width: 1300px) {
    .outer-ad-left {
        display: none !important;
        max-width: 0;
    }
}

@media screen and (min-width: 1300px) {
    .outer-ad {
        width: 200px;
    }
}

@media screen and (min-width: 1500px) {
    .outer-ad {
        width: 300px;
    }
}