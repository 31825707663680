.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.39); */
}

.send a {
    color: var(--bs-secondary);
}

.send a:hover {
    color: var(--bs-primary-text-emphasis);
}

.send .circle > i {
    font-size: 34px;
}

.recv .circle > i {
    font-size: 20px;
}

.hr {
    margin: 5px 0px;
    width: 20px;
}

